<template>
  <div>
    <section class="self_sec" style="min-height: calc(100vh - 285px);">
      <div class="container-fluid">
        <div v-if="loading" class="loader"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="login_signup_form py_60 mt_10">
              <FlashMessage :message="message" :error="error" />
              <h2 class="text_black font_size_36">Forgot Password</h2>
              <p class="text_concord font_size_18 mb_40">
                Send a Reset Password Link!
              </p>
              <form @submit.prevent="forgotPassword" data-vv-scope="forgotPasswordForm">
                <div class="form-group">
                  <label class="font_size_24 mb_20 font_bold">Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    class="form-control"
                    v-model="email"
                    name="email"
                    v-validate="'required|email'"
                  />
                  <small class="error text-danger" v-show="errors.has('forgotPasswordForm.email')">
                  {{ errors.first("forgotPasswordForm.email") }}
                </small>
                </div>
                <div
                  class="
                    mt_68
                    d-flex
                    justify-content-between
                    align-items-center
                    btn_link_box
                  "
                >
                  <div>
                    <button class="btn btn_primary">Send</button>
                  </div>
                  <div class="accout_box">
                    <p class="m-0">
                      <router-link :to="{ name: 'login' }"
                        ><span>Login</span></router-link
                      >
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="col-md-6 p-0">
            <div class="self_img">
              <img src="../assets/images/schedule_img.png" alt="img" />
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";
import commonFunction from "../mixin/commonFunction";
export default {
  name: "ForgotPassword",
  components: {
    FlashMessage,
  },
  mixins: [commonFunction],
  data() {
    return {
      email: null,
      error: null,
      message: null,
      loading: false
    };
  },
  mounted(){
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
  },
  methods: {
    forgotPassword() {
      var infoMsg = "If this account is registered then instruction to reset your password will be sent to your email inbox.";
      this.$validator.validateAll("forgotPasswordForm").then((isValid) => {
        if (isValid) {
          this.error = null;
          this.message = null;
          this.loading = true;
          this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          const payload = {
            email: this.email,
          };
          AuthService.forgotPassword({request_data : this.encodeAPIRequest(payload)},)
          .then(() => {
            // this.message = "If this account is registered then instruction to reset your password will be sent to your email inbox."
            this.$toast.success(infoMsg, {
              position: "top-right",
              duration: 5000,
            });
            this.email = null;
            this.$validator.reset();
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }).catch(() => {
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            this.email = null;
            this.$validator.reset();
            this.$toast.success(infoMsg, {
              position: "top-right",
              duration: 5000,
            });
            
          });
          // .catch((error) => (this.error = getError(error))).finally(() => (this.$parent.$refs.fullpageloader.is_full_page_loader = false));
        }
      });
    },
  },
};
</script>
